<template>
  <div class="help-category-page">
    <!-- <div v-if="content && content.data && content.data.heading" class="introduction">
      <div class="illustration"><img alt="" src="/cms/images/597617192.jpg"/></div>
      <div v-html="content.data.heading"></div>
    </div> -->
    <div class="articles">
      <HCard
        hoverZoom
        type="free"
        class="article-card"
        v-for="(article, articleIndex) in articles"
        :key="articleIndex"
        :to="getArticleLink(article)"
      >
        <div class="banner">
          <div class="splash">
            <img class="background" :src="article.pictureUrl" border="0" />
          </div>
        </div>
        <div class="content">
          <div class="content-title">{{ article.title }}</div>
        </div>
      </HCard>
    </div>
  </div>
</template>

<script>
import Routes from '@/constants/routes'

import Http from '@/http'

import residentMixin from '@/mixins/residentMixin'
import ToastMixin from '@/mixins/toasts'

import {
  HCard
} from '@happytal/bo-ui-library'

export default {
  name: 'HelpCategory',

  mixins: [
    ToastMixin,
    residentMixin
  ],

  components: {
    HCard
  },

  data () {
    return {
      dataArticles: []
    }
  },

  computed: {
    currentInstititionId () {
      return this.$store.getters['dataUser/getInstitutionId']()
    },
    articles () {
      return _.map(this.dataArticles, (article) => {
        return {
          id: article.id,
          title: article.title,
          pictureUrl: _.get(article, 'image.url', '')
        }
      })
    }
  },

  methods: {
    getArticleLink (article) {
      return {
        name: this.$_residentId ? Routes.ResidentHelpCategoryDetail : Routes.HelpCategoryDetail,
        params: {
          article_id: article.id
        }
      }
    },
    getArticles () {
      Http.apis.strapi.get(`/articles?institutions.institution_id=0&institutions.institution_id=${this.currentInstititionId}&_sort=order:ASC`)
      .then((response) => {
          this.dataArticles = _.get(response, 'data', null)
          //console.log('data', this.dataArticles)
      })
      .catch((error) => {
          this.$_showErrorToast(`Une erreur s'est produite (${error}).`)
      })
    }
  },
  mounted () {
    this.getArticles()
  }
}
</script>

<style lang="scss" scoped>
.help-category-page {

  .introduction {
    display: flex;

    .illustration {
      margin: 0px 30px 0px 0px;
    }
  }
  .articles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 38px;
    row-gap: 40px;

    .article-card {
      display: flex;
      flex-direction: column;
      padding: 0;

      .banner {
        position: relative;
        background: #EFEFEF;
        border-radius: 6px 6px 0px 0px;

        .splash {
          height: 168px;
          overflow: hidden;
          border-radius: 6px 6px 0px 0px;

          .background {
            width: 100%;
            border-radius: 6px 6px 0px 0px;
          }
        }
      }
      .content {
        flex-grow: 1;
        position: relative;

        .content-title {
          padding: 30px 20px 30px 20px;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 29px;
          text-align: center;
          color: black;
        }
      }
    }
  }
}
</style>
